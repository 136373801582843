<template>
  <div class="login-container">
    <a-row>
      <a-col :xs="0" :md="0" :sm="12" :lg="14" :xl="16"></a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="10" :xl="6">
        <div class="login-container-form">
          <div class="login-container-hello">&nbsp;</div>
          <div class="login-container-title">欢迎来到 {{ title }}</div>
          <a-form :model="form" ref="formRef" :rules="rules">
            <a-form-item name="username">
              <a-input
                v-model:value="form.username"
                type="text"
                placeholder="请输入用户名"
              >
                <template v-slot:prefix>
                  <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item name="password">
              <a-input
                v-model:value="form.password"
                type="password"
                placeholder="请输入密码"
              >
                <template v-slot:prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item name="code">
              <a-input
                v-model:value="form.code"
                type="text"
                placeholder="点击更新验证码"
                style="width: 220px; margin-right: 5px"
              >
                <template v-slot:prefix>
                  <VerifiedOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input>
              <img
                style="margin-top: 3px; height: 43px"
                :src="captchaUrl"
                @click="updateCaptcha"
              />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" size="large" @click="handleSubmit">
                登录
              </a-button>
            </a-form-item>
          </a-form>
          <div class="login-container-forget">
            <a @click="handleUserRegister">用户注册</a
            ><a @click="handleForgetPassword">忘记密码</a>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
  <div>
    <ForgetForm
      :modalVisible="modalVisible"
      @handleModalVisible="handleModalVisible"
      @saveCallBack="saveCallBack"
    />
    <RegisterForm
      :modalVisible="registerModalVisible"
      :isCreate="isCreate"
      :editData="editData"
      @handleModalVisible="handleRegisterModalVisible"
      @saveCallBack="saveCallBack1"
    />
  </div>
</template>
<script>
import {
  UserOutlined,
  LockOutlined,
  VerifiedOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, toRefs } from "vue";
import ForgetForm from "./components/ForgetForm.vue";
import RegisterForm from "../user/userlist/components/UserForm.vue";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "Login",
  components: {
    UserOutlined,
    LockOutlined,
    VerifiedOutlined,
    ForgetForm,
    RegisterForm,
  },
  setup() {
    const formRef = ref();

    const state = reactive({
      isCreate: 2,
      modalVisible: false,
      registerModalVisible: false,
      editData: {
        id: "",
        realname: "",
        code: "",
        username: "",
        nickname: "",
        phone: "",
        unit: "",
        profession: "",
        depart: "",
        memo: "",
      },
      form: {
        username: "",
        password: "",
        code: "",
      },
      title: "短信管理平台",
      captchaUrl: "/api/captcha?time=" + new Date(),
    });

    const rules = {
      username: [
        { required: true, message: "用户名不能为空！", trigger: "blur" },
      ],
      password: [
        { required: true, message: "密码不能为空！", trigger: "blur" },
      ],
      code: [{ required: true, message: "验证码不能为空！", trigger: "blur" }],
    };

    const handleSubmit = async () => {
      formRef.value
        .validate()
        .then((success) => {
          //console.log(toRaw(formRef));
          if (success) {
            store.dispatch("LoginResult", state.form);
            //console.log(res);
            //onsole.log(store.state.userInfo);
          } else {
            message.error("登录失败！！！");
            return false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    const updateCaptcha = () => {
      state.captchaUrl = "/api/captcha?time=" + new Date();
    };

    const handleForgetPassword = () => {
      handleModalVisible(true);
    };

    const handleUserRegister = () => {
      handleRegisterModalVisible(true);
    };

    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };

    const handleRegisterModalVisible = (flag) => {
      state.registerModalVisible = flag;
    };

    const saveCallBack = (code) => {
      console.log(code);
      // if (code == 200) {
      //   initUserList(state.searchParam);
      // }
    };

    const saveCallBack1 = (code) => {
      console.log(code);
      // if (code == 200) {
      //   initUserList(state.searchParam);
      // }
    };

    const store = useStore();

    return {
      ...toRefs(state),
      handleModalVisible,
      handleRegisterModalVisible,
      handleForgetPassword,
      handleUserRegister,
      handleSubmit,
      updateCaptcha,
      saveCallBack,
      saveCallBack1,
      formRef,
      rules,
    };
  },
});
</script>

<style lang="scss">
.login-container {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/login_images/login_background.png");
  background-size: cover;
  overflow-x: hidden;
  // .logintop {
  //   width: 668px;
  //   height: 150px;
  //   position: absolute;
  //   top: 43%;
  //   left: 50%;
  //   margin-left: -400px;
  //   margin-top: -300px;
  //   padding: 0 20px;
  //   h2 {
  //     height: 150px;
  //     width: 666px;
  //     margin: 0 auto;
  //     background: url("~@/assets/login_images//indexlogo.png") no-repeat;
  //     text-indent: -9999px;
  //   }
  // }
  &-form {
    width: 375px;
    height: 400px;
    padding: 4vh;
    margin-top: calc((100vh - 400px) / 2);
    margin-right: 20px;
    margin-left: 20px;
    background: url("~@/assets/login_images/login_form.png");
    background-size: 100% 100%;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
  }
  &-hello {
    height: 36px;
    background: url("~@/assets/login_images/hello.png");
    //font-size: 32px;
    //color: #fff;
  }
  &-title {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    color: rgb(200, 248, 178);
  }
  &-tips {
    position: fixed;
    // bottom: @vab-margin;
    width: 100%;
    height: 40px;
    color: rgba(255, 255, 255, 0.856);
    text-align: center;
  }
  &-forget {
    display: flex;
    margin-left: 80px;
    a {
      text-decoration: underline;
      color: rgba(188, 194, 247, 0.8);
      margin-right: 50px;
    }
  }
  .ant-col {
    width: 100%;
    padding: 0px;
  }
  .ant-input {
    height: 30px;
  }
  .ant-btn {
    width: 100%;
    height: 40px;
    border-radius: 99px;
  }
  .ant-form-item {
    margin-bottom: 18px;
  }
}
</style>
